// src/repositories/FirebaseRepository.js



import { firestore, auth } from '../firebaseConfig';
import { signOut, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, updateDoc, addDoc, collection, deleteDoc, serverTimestamp } from 'firebase/firestore';
import UserProfile from '../models/UserProfile';
import Conversation from '../models/Conversation';
import { Message } from '../models/Message';

class FirebaseRepository {

    registerUser = async (email, password, fullName) => {
        const url = process.env.REACT_APP_CALYPSO_AUTH_API_URL + '/register';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    requestType: 'registration',
                    fullName: fullName,
                }),
            });

            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message || 'Registration failed');
            }
            return {
                success: responseData['success'],
                reason: responseData['reason'],
            };
        } catch (error) {
            console.error('Error during user registration:', error);
            return { success: false, reason: error.message };
        }
    }

    async signOut() {
        try {
            await signOut(auth);
            console.log('User logged out successfully');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    }

    async signIn(email, password) {
        const verifyUrl = process.env.REACT_APP_CALYPSO_AUTH_API_URL + '/checkVerify';

        try {
            const verifyResponse = await fetch(verifyUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const verifyData = await verifyResponse.json();
            if (verifyResponse.ok && !verifyData.emailVerified) {
                throw new Error('Email not verified');
            } else if (!verifyResponse.ok) {
                throw new Error(verifyData.error || 'Error checking email verification');
            }

            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            return {
                success: true,
                user: userCredential.user,
            };
        } catch (error) {
            console.error('Error during sign-in:', error);
            return { success: false, reason: error.code === 'auth/invalid-credential' ? "Invalid sign in credentials! Try again." : error.message };
        }
    }

    async createMessage(role, content) {
        const messageDocRef = await addDoc(collection(firestore, 'messages'), {
            role: role,
            content: content,
            timestamp: serverTimestamp(),
        });
        const message = new Message(role, content, messageDocRef.id, serverTimestamp(), true)
        return message;
    }

    async getMessages(messageIds) {
        const messages = [];

        for (const id of messageIds) {
            const documentRef = doc(firestore, 'messages', id);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {
                const data = documentSnapshot.data();
                data['id'] = documentSnapshot.id;
                messages.push(Message.fromFirestore(data));
            }
        }

        return messages;
    }

    async fetchUserProfile(userId) {
        const userDocRef = doc(firestore, 'users', userId);
        const userSnapshot = await getDoc(userDocRef);


        if (!userSnapshot.exists) {
            throw new Error('User not found');
        }

        const userData = userSnapshot.data();
        return new UserProfile(
            userData.fullName,
            userData.email,
            userData.conversationIds,
            userData.userId,
            userData.activationStatus
        );
    }

    async deleteConversation(conversationId) {
        const conversationRef = doc(firestore, 'conversations', conversationId);
        await deleteDoc(conversationRef);
    }

    async createNewConversation() {
        const newConversationData = {
            timestamp: serverTimestamp(),
            title: new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }), // Adjust the date format as needed
            messageIds: [],
        };

        const docRef = await addDoc(collection(firestore, 'conversations'), newConversationData);
        return new Conversation(newConversationData.timestamp, newConversationData.title, [], docRef.id, newConversationData.messageIds);
    }

    async getConversations(conversationIds) {
        const conversations = [];

        for (const id of conversationIds) {
            const conversationDocRef = doc(firestore, 'conversations', id);
            const conversationsSnapshot = await getDoc(conversationDocRef);

            if (conversationsSnapshot.exists()) {
                const data = conversationsSnapshot.data();
                if (data) { // Check if data is not undefined
                    data.id = conversationsSnapshot.id; // Add the document ID to the data map
                    const conversation = Conversation.fromFirestore(data);
                    conversations.push(conversation);
                } else {
                    console.warn(`Data for conversation with id ${id} is undefined.`);
                    // Optionally handle the case where data is undefined
                }
            } else {
                console.warn(`Conversation with id ${id} does not exist or has been deleted.`);
                // Optionally handle the missing conversation case here
            }
        }
        return conversations;
    }

    async updateUserProfile(user) {
        const userRef = doc(firestore, 'users', user.userId);
        const userData = {
            conversationIds: user.conversationIds,
        };
        await updateDoc(userRef, userData);
    }

    async updateConversation(conversation) {
        const conversationRef = doc(firestore, 'conversations', conversation.id);
        const conversationData = {
            messageIds: conversation.messageIds,
            title: conversation.title
        };

        try {
            await updateDoc(conversationRef, conversationData);
        } catch (error) {
            console.error("Error updating conversation: ", error);
        }
    }
    async resetPassword(email) {
        const resetPasswordUrl = process.env.REACT_APP_CALYPSO_RESET_PASSWORD_API_URL;
        try {
            const response = await fetch(resetPasswordUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });
    
            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message || 'Failed to reset password');
            }
            // Success
            return {
                success: true,
                message: 'Password reset email sent successfully. Please check your inbox.',
            };
        } catch (error) {
            console.error('Failed to reset password:', error);
            // Error
            return {
                success: false,
                message: error.message || 'Failed to reset password',
            };
        }
    }
    
}

const firebaseRepositoryInstance = new FirebaseRepository();
export default firebaseRepositoryInstance;