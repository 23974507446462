// src/components/HomeView.js

import React, { useEffect, useState } from 'react';
import SessionExpiredPage from './SessionExpiredPage';
import ChatWindow from './ChatWindow';
import LoadingPage from './LoadingPage';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import { updateUserProfileAction } from '../actions/userActions';
import { setCurrentConversationAction, createTemporaryConversation } from '../actions/conversationActions';
import './HomeView.css';


const SIDEBAR_WIDTH = 256; // Sidebar width in pixels

const HomeView = () => {
    const dispatch = useDispatch();
    const { userProfile, loading } = useSelector(state => state.userProfile);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleConversationSelect = (selectedConversationId) => {
        if (selectedConversationId === 'temp-id') {
            dispatch(setCurrentConversationAction('temp-id'));
        } else {
            dispatch(setCurrentConversationAction(selectedConversationId));
        }
    };

    useEffect(() => {
        // Dispatch the action to create a temporary conversation
        dispatch(setCurrentConversationAction('temp-id'));
        dispatch(createTemporaryConversation());
    }, [dispatch]);

    if (loading) {
        return <LoadingPage />;
    }

    if (!userProfile) {
        return <SessionExpiredPage onRelogin={() => {/* Handle re-login logic */ }} />;
    }

    const handleNewConversation = async (newConversation) => {
        if (userProfile) {
            // Update user's profile with the new conversation ID
            const updatedUserProfile = {
                ...userProfile,
                conversationIds: [...userProfile.conversationIds, newConversation.id]
            };
            dispatch(updateUserProfileAction(updatedUserProfile));
        }

        // Set the current conversation ID in Redux
        dispatch(setCurrentConversationAction(newConversation.id));
    };
    return (
        <div className='layout-container'> 
            <div style={{ position: 'relative', width: isSidebarOpen ? `${SIDEBAR_WIDTH}px` : '0', flexShrink: 0 }}>
                <Sidebar
                    user={userProfile}
                    selectConversation={handleConversationSelect}
                    isSidebarOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}
                />
                <button
                    className="chevron-button"
                    onClick={toggleSidebar}
                    style={{ left: isSidebarOpen ? `${SIDEBAR_WIDTH}px` : '0' }}>
                    <i className={`fas fa-chevron-${isSidebarOpen ? 'left' : 'right'}`}></i>
                </button>
            </div>
            <ChatWindow onNewConversationCreated={handleNewConversation} />
        </div>
    );
};

export default HomeView;