// src/actions/messageActions.js
import { getMessages, createMessage } from '../services/FirebaseService';

export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'FETCH_MESSAGES_ERROR';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const fetchMessages = (messageIds) => async (dispatch) => {
    dispatch({ type: FETCH_MESSAGES });

    try {
        const messages = await getMessages(messageIds);
        dispatch({ type: FETCH_MESSAGES_SUCCESS, payload: messages });
    } catch (error) {
        dispatch({ type: FETCH_MESSAGES_ERROR, payload: error });
    }
};

export const createMessageAction = (role, content) => async (dispatch) => {
    dispatch({ type: CREATE_MESSAGE });
    try {
        const message = await createMessage(role, content);
        dispatch({ type: CREATE_MESSAGE_SUCCESS, payload: message });
        return message;
    } catch (error) {
        dispatch({ type: CREATE_MESSAGE_ERROR, payload: error });
        console.error('Error creating message:', error);
    }
};

// Action creator for clearing messages
export const clearMessages = () => ({
    type: CLEAR_MESSAGES,
});

// Example in messageActions.js
export const addMessageChunk = (chunk) => ({
    type: 'ADD_MESSAGE_CHUNK',
    payload: chunk
});


// Redux action to add a message to the store
export const addMessage = (message) => ({
    type: 'ADD_MESSAGE', // This type should be handled in your messageReducer
    payload: message
});