// ChatRepository.js

class ChatRepository {
    constructor() {
        this.apiKey = process.env.REACT_APP_OPEN_AI_API_KEY;
        this.engine = 'gpt-4-1106-preview'; // Engine ID
    }

    async generateConversationTitle(message) {
        let totalMessageTokens = message.length;

        // Calculate maxTokens based on the total tokens used by the messages
        let maxTokens = 4096 - totalMessageTokens;

        const requestBody = {
            model: this.engine,
            messages: [
                { role: 'system', content: "Based on the following user message, craft a concise and engaging title that encapsulates the essence of their exchange. The title should be under 10 words, clearly reflect the conversation's main themes, and be intriguing enough to draw attention:" },
                { role: 'user', content: message },
            ],
            max_tokens: maxTokens,
            temperature: 0.7,
            n: 1,
            stop: '\n',
        };

        const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.apiKey}`
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error('Failed to generate conversation title');
        }

        const data = await response.json();
        const title = data.choices[0].message.content.trim().slice(1, -1);

        if (!title) {
            throw new Error('Failed to generate conversation title');
        }
        return title;
    }

    async sendMessage(messages, handleNewMessageChunk) {
        // Calculate the total tokens used by the messages
        let totalMessageTokens = messages.reduce((sum, message) => sum + message.content.length, 0);

        // Calculate maxTokens based on the total tokens used by the messages
        let maxTokens = 4096 - totalMessageTokens;
        maxTokens = Math.max(maxTokens, 1024); // Ensuring it doesn't fall below 1024

        const requestBody = {
            model: this.engine,
            messages: messages, // Array of message objects
            max_tokens: maxTokens,
            temperature: 0.7,
            stream: true,
        };

        const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.apiKey}`
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.body) {
            throw new Error('No response body');
        }

        const reader = response.body.getReader();
        let completeResponse = '';

        while (true) {
            const { value, done } = await reader.read();
            if (done) break;
        
            const chunk = new TextDecoder().decode(value);
            const lines = chunk.split('\n');
        
            for (const line of lines) {
                if (line.startsWith('data: ')) {
                    const jsonPart = line.substring(6); // Remove "data: "
                    if (jsonPart === '[DONE]') {
                        return completeResponse; // Handle the end of the stream
                    }
        
                    try {
                        const jsonChunk = JSON.parse(jsonPart);
                        if (jsonChunk.choices) {
                            for (const choice of jsonChunk.choices) {
                                if (choice.delta?.content !== undefined) {
                                    // Call the callback function with the new content
                                    handleNewMessageChunk(choice.delta.content);
                                    completeResponse += choice.delta.content;
                                }
                            }
                        }
                    } catch (e) {
                        console.error('Error parsing JSON chunk:', e);
                    }
                }
            }
        }
        return completeResponse;
    }
}
const chatRepositoryInstance = new ChatRepository();
export default chatRepositoryInstance;