// src/components/IdentityBox.js

import React, { useState } from 'react';
import logo from '../images/smartbcba512.png';
import { useDispatch, useSelector } from 'react-redux';
import { signInAction, registerUserAction } from '../actions/userActions'; // Adjust the import paths as necessary
import './IdentityBox.css';
// import { resetPasswordAction } from '../actions/userActions'; // Update the path as needed
import { handleResendVerification } from '../api/api'; // Import the function to handle resending verification emails

const IdentityBox = () => {
    const [isRegistering, setIsRegistering] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
    const errorMessage = useSelector(state => state.userProfile.error);
    const registrationSuccessful = useSelector(state => state.userProfile.registrationSuccessful);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false); // Add this line
    const [showTermsWarning, setShowTermsWarning] = useState(false); // New state for showing the warning
    const [emailSent, setEmailSent] = useState(false);
    const dispatch = useDispatch();

    const handleSignIn = () => {
        dispatch(signInAction(email, password));
    };

    const handleResendVerificationEnhanced = async (email) => {
        try {
            await handleResendVerification(email);
            setEmailSent(true); // Set the success state to true
        } catch (error) {
            console.error('Failed to resend verification email:', error);
            setEmailSent(false); // Ensure to handle failure cases appropriately
        }
    };

    const modifiedHandleRegister = () => {
        if (!isTermsAccepted) {
            setShowTermsWarning(true); // Show warning if terms are not accepted
            setTimeout(() => setShowTermsWarning(false), 5000); // Hide warning after 5 seconds
            return;
        }
        // Proceed with sign-in process
        handleRegister();
    };

    const handleRegister = () => {
        if (password !== confirmPassword) {
            // Dispatch an action to set the error message
            dispatch({ type: 'REGISTRATION_FAILURE', payload: "Passwords do not match." });
            return; // Prevent the registration from proceeding
        }

        dispatch(registerUserAction(email, password, fullName));
    };

    const toggleResetPasswordForm = () => {
        setShowResetPasswordForm(!showResetPasswordForm);
    };
    const ResetPasswordButton = () => {
        return (
            <button
                className="inline-block align-baseline font-bold text-sm custom-font hover:text-blue-800 mt-2"
                onClick={toggleResetPasswordForm}>
                Forgot Password?
            </button>
        );
    };

    const handleResetPassword = async (resetEmail) => {
        const resetPasswordUrl = process.env.REACT_APP_CALYPSO_RESET_PASSWORD_API_URL;
        try {
            const response = await fetch(resetPasswordUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: resetEmail }),
            });

            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message || 'Failed to reset password');
            }
            setFeedbackMessage('Password reset email sent successfully. Please check your inbox.');
        } catch (error) {
            console.error('Failed to reset password:', error);
            setFeedbackMessage(error.message || 'Failed to reset password');
        }
    };

    const ResetPasswordForm = ({ onSubmit, onCancel }) => {
        const [formEmail, setFormEmail] = useState(''); // Separate state for the form's email

        return (
            <div className="reset-password-form">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(formEmail);
                }}>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={formEmail}
                        onChange={(e) => setFormEmail(e.target.value)}
                        required
                    />
                    <button type="submit" className="reset-password-button">Send Reset Link</button>
                </form>
                <button onClick={onCancel} className="reset-password-button">Back</button>

                {feedbackMessage && <p>{feedbackMessage}</p>}
            </div>
        );
    };

    return (
        <div className="bg-white p-10 rounded-lg shadow-lg">
            <div className="flex items-center justify-center mb-6">
                <div className="flex items-center justify-center mb-6">
                    <img src={logo} alt="Logo" className="w-1/2" />
                </div>
            </div>

            {showResetPasswordForm ? (
                <ResetPasswordForm
                    onSubmit={handleResetPassword}
                    onCancel={() => setShowResetPasswordForm(false)} />
            ) : isRegistering ? (
                // Registration Form
                <>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullname">
                            Full Name
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="fullname"
                            type="text"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <label className="block text-gray-500 text-xs font-bold mb-2" htmlFor="email">
                            Use your organization email address (i.e., @smartbcba.com)
                        </label>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                            Confirm Password
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    {registrationSuccessful === true && (
                        <p className="text-green-500 text-sm mt-2">
                            Registration Successful! To complete your sign-up and access your account, you must verify your email. We've sent a verification link to your email address. Please click on it to proceed. If you do not recieve it, please check your spam folder, or <button onClick={() => handleResendVerification(email)} className='underline'>click here</button> to resend the email.
                        </p>
                    )}
                    {/* Start of Checkbox for Terms & Conditions */}
                    <div className="mb-6">
                        <input
                            id="termsAndConditions"
                            type="checkbox"
                            className="mr-2 leading-tight"
                            // You might want to manage this checkbox's state to handle form submission logic
                            onChange={(e) => setIsTermsAccepted(e.target.checked)}
                        />
                        <label htmlFor="termsAndConditions" className="text-sm text-gray-700">
                            By registering, you agree to Smart BCBA's <a href="https://smartbcba.com/terms" className="text-blue-600 hover:text-blue-800 visited:text-purple-600">terms & conditions</a> and <a href="https://smartbcba.com/privacy" className="text-blue-600 hover:text-blue-800 visited:text-purple-600">Privacy Policy</a>.
                        </label>
                        {showTermsWarning && <p className="text-red-500 text-xs mt-1">You must accept the terms and conditions to proceed.</p>}
                    </div>
                    {/* End of Checkbox for Terms & Conditions */}
                    <div className="flex items-center justify-between">
                        <button className="custom-bg text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={() => modifiedHandleRegister()}
                        >
                            Register
                        </button>
                        <ResetPasswordButton />
                        <button className="inline-block align-baseline font-bold text-sm custom-font hover:text-blue-800" onClick={() => setIsRegistering(false)}>
                            Existing User
                        </button>
                    </div>
                </>
            ) : (
                // Login Form
                <>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="custom-bg hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleSignIn} // Just call handleSignIn without arguments
                        >
                            Sign In
                        </button>
                        <ResetPasswordButton />
                        <button
                            className="inline-block align-baseline font-bold text-sm custom-font hover:text-blue-800"
                            onClick={() => setIsRegistering(true)}
                        >
                            New User
                        </button>
                    </div>
                    <label htmlFor="termsAndConditions" className="text-sm text-gray-700">
                        By signing in, you agree to Smart BCBA's <a href="https://smartbcba.com/terms" className="text-blue-600 hover:text-blue-800 visited:text-purple-600">terms & conditions</a> and <a href="https://smartbcba.com/privacy" className="text-blue-600 hover:text-blue-800 visited:text-purple-600">Privacy Policy</a>.
                    </label>
                    {errorMessage && (
                        <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
                    )}
                    {errorMessage && errorMessage.trim().toLowerCase() === 'email not verified'.toLowerCase() && (
                        <button
                            onClick={() => handleResendVerificationEnhanced(email)}
                            className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg hover:shadow transition duration-150 ease-in-out"
                        >
                            Resend Verification Email
                        </button>
                    )}
                    {emailSent && (
                        <p className="text-green-500 mt-2">A verification email has been sent to your inbox.</p>
                    )}
                </>
            )}
        </div>
    );
};

export default IdentityBox;
