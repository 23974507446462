// src/components/ChatWindow.js
import React, { useState, useEffect, useRef } from 'react';
import AssistantCell from './AssistantCell';
import UserCell from './UserCell';
import './ChatWindow.css';
import '../index.css';
import ExamplePromptCell from './ExamplePromptCell';
import logo from '../images/logo.png';

import { useDispatch, useSelector } from 'react-redux';
import { sendMessage as sendMessageToChat, createConversationTitle } from '../actions/chatActions'; // Adjust the path as needed
import { createConversationAction, updateConversationAction, setCurrentConversationAction } from '../actions/conversationActions';
import { fetchMessages, createMessageAction, addMessageChunk } from '../actions/messageActions';


const examplePrompts = [
    "Create 5 SMART, social goals to improve a young child's sharing.",
    "Generate a template session frequency table for measuring if a child correctly identifies facial expressions.",
    "What are some ABC strategies to reduce and replace a child's tantrums.",
    "Draft a treatment plan template for a young child with self injurious behavior.",
];

function ChatWindow({ onNewConversationCreated }) {
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const { messages } = useSelector(state => state.messageReducer);
    const currentConversationId = useSelector(state => state.conversation.currentConversationId);
    const currentConversation = useSelector(state => state.conversation.conversations[currentConversationId]);
    const [isReceivingMessage, setIsReceivingMessage] = useState(false);
    const [sendAfterSet, setSendAfterSet] = useState(false);
    const textareaRef = useRef(null);

    useEffect(() => {
        if (currentConversation && currentConversation.messageIds) {
            dispatch(fetchMessages(currentConversation.messageIds));
        }
    }, [currentConversation, dispatch]);

    const handleNewMessageChunk = (newContent) => {
        dispatch(addMessageChunk(newContent));
    };

    const handleKeyDown = (e) => {
        // Check if Enter key was pressed without the Shift key
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent the default action
            handleSendMessage();
        }
    };

    function adjustHeight(e) {
        const minHeight = 32; // Minimum height for two lines (adjust as needed)
        const maxHeight = 6 * 16; // Maximum height for 6 lines (adjust as needed)
        e.target.style.height = 'inherit'; // Reset the height
        const newHeight = Math.min(e.target.scrollHeight, maxHeight);
        e.target.style.height = `${Math.max(newHeight, minHeight)}px`; // Set the new height, not less than minHeight

        // Adjust overflow based on whether the content exceeds maxHeight
        if (e.target.scrollHeight > maxHeight) {
            e.target.style.overflow = 'auto';
        } else {
            e.target.style.overflow = 'hidden';
        }
    }

    const exampleCellTapped = (promptText) => {
        setMessage(promptText); // Set the current message to the tapped cell's content
        setSendAfterSet(true); // Indicate that a send should occur after the message is set
    };

    const handleSendMessage = async () => {
        var shouldNameConversation = false;
        if (!message.trim()) return;

        // Create and send the user message
        const userMessage = await dispatch(createMessageAction('user', message));

        let updatedConversation;
        if (currentConversationId === 'temp-id') {
            // Create a new conversation in Firebase and update the Redux store
            const newConversation = await dispatch(createConversationAction());
            updatedConversation = {
                title: newConversation.title,
                id: newConversation.id,
                messageIds: [userMessage.id],
                messages: [userMessage]
            };
            // Dispatch action to set the new conversation as current
            dispatch(setCurrentConversationAction(newConversation.id));
            // Call the callback to handle new conversation creation
            onNewConversationCreated(updatedConversation);
            shouldNameConversation = true;
        } else {
            // Use the existing conversation
            updatedConversation = {
                ...currentConversation,
                messageIds: [...currentConversation.messageIds, userMessage.id],
                messages: [...messages, userMessage]
            };
        }

        // Update the conversation in Redux
        await dispatch(updateConversationAction(updatedConversation));

        // Reset the textarea height
        if (textareaRef.current) {
            textareaRef.current.style.height = '2em'; // Set this to your initial height
            textareaRef.current.style.overflow = 'hidden'; // Hide the scrollbar
        }

        // Reset the message input field
        setMessage('');
        // Prepare and dispatch the request to the ChatRepository
        setIsReceivingMessage(true);
        const formattedMessages = updatedConversation.messages.map(msg => msg.toJsonForGPT());
        const responseContent = await dispatch(sendMessageToChat(formattedMessages, handleNewMessageChunk));
        setIsReceivingMessage(false);

        if (responseContent) {
            // Create and dispatch the assistant's response message
            const assistantMessage = await dispatch(createMessageAction('assistant', responseContent));
            const conversationTitle = shouldNameConversation ? await dispatch(createConversationTitle(assistantMessage.content)) : updatedConversation.title;
            // Update the conversation with the assistant's message ID
            updatedConversation = {
                ...updatedConversation,
                title: conversationTitle ?? updatedConversation.title,
                messageIds: [...updatedConversation.messageIds, assistantMessage.id],
                messages: [...updatedConversation.messageIds, assistantMessage]
            };
            // Update the Redux store with the modified conversation
            dispatch(updateConversationAction(updatedConversation));
        }
    };

    useEffect(() => {
        if (sendAfterSet) {
            handleSendMessage(); // Call your send message handler
            setSendAfterSet(false); // Reset the indicator
        }
        // eslint-disable-next-line
    }, [sendAfterSet]); // Only re-run the effect if `sendAfterSet` changes

    return (
        <div className='chat-window-container relative'>
            {/* Existing Chat Window Content */}
            <div className="flex-1 flex flex-col">
                <div className="flex items-center justify-center mb-6 shadow-header">
                    <img src={logo} alt="Logo" className="logo-max-size" />
                </div>
                <div className="flex-1 overflow-auto" style={{ maxHeight: "89vh" }}> {/* Adjust maxHeight as needed */}
                    {messages.map((message, index) => {
                        const isUser = message.role === "user";
                        const isLastMessage = index === messages.length - 1;

                        return isUser ? (
                            <UserCell key={index} message={message.content} />
                        ) : (
                            <AssistantCell
                                key={index}
                                message={message.content}
                                isTyping={isLastMessage && message.isNew && isReceivingMessage}
                            />
                        );
                    })}
                </div>
                {/* Overlay with Example Prompts */}
                {messages.length === 0 && (
                    <div className="absolute bottom-0 left-0 right-0 pb-26 z-10" style={{ bottom: '5rem' /* Adjust this value to match the chat bar height */ }}>
                        <div className="grid grid-cols-2 gap-4 p-4 bg-white bg-opacity-90 shadow">
                            {examplePrompts.map((prompt, index) => (
                                <ExamplePromptCell
                                    key={index}
                                    promptText={prompt}
                                    onCellTap={exampleCellTapped}
                                />
                            ))}
                        </div>
                    </div>
                )}
                <div className="flex items-center justify-between h-26 p-2 border-t text-area-container">
                    <textarea
                        ref={textareaRef}
                        placeholder="Enter message"
                        className="flex-1 border rounded focus:outline-none overflow-hidden"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onInput={(e) => adjustHeight(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        style={{ minHeight: '2em', maxHeight: '6em', resize: 'none', padding: '10px' }}
                        rows="1"
                    />
                    <button
                        className="focus:outline-none ml-4"
                        onClick={handleSendMessage}
                        disabled={isReceivingMessage}
                    >
                        <i className="fas fa-paper-plane"></i>
                    </button>
                </div>
            </div>
        </div>
    );



}

export default ChatWindow;
