import React from 'react';
import './UserCell.css'

function UserCell({ message }) {
    return (
        <div className="user-cell">
            <div className="user-avatar">
                <i className="fas fa-user icon" />
            </div>
            <div className="user-message">
                {message}
            </div>
        </div>
    );
}

export default UserCell;
