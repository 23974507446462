// src/reducers/chatReducer.js

const initialState = {
  messages: [],
  loading: false,
  sessionExpired: false,
  // ... other state properties ...
};


function chatReducer(state = initialState, action) {
  switch (action.type) {
      case 'SEND_MESSAGE':
          return {
              ...state,
              loading: true,
          };
      case 'SEND_MESSAGE_SUCCESS':
          return {
              ...state,
              loading: false,
          };
      case 'SEND_MESSAGE_ERROR':
          return {
              ...state,
              loading: false,
              // You might want to handle the error in some way
          };
      case 'SET_LOADING':
          return {
              ...state,
              loading: action.payload,
          };
      case 'SET_SESSION_EXPIRED':
          return {
              ...state,
              sessionExpired: true,
          };
      case 'SET_SESSION_ACTIVE':
          return {
              ...state,
              sessionExpired: false,
          };
      // ... handle other action types ...
      default:
          return state;
  }
}

export default chatReducer;
