// src/actions/conversationActions.js
import firebaseRepository from '../repositories/FirebaseRepository';

// Action Types
export const CONVERSATIONS_LOADING = 'CONVERSATIONS_LOADING';
export const FETCH_CONVERSATIONS_SUCCESS = 'FETCH_CONVERSATIONS_SUCCESS';
export const FETCH_CONVERSATIONS_ERROR = 'FETCH_CONVERSATIONS_ERROR';

// Action Creator
export const fetchConversations = (conversationIds) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONVERSATIONS_LOADING });

    // Fetch conversations using the provided IDs
    const conversations = await firebaseRepository.getConversations(conversationIds);

    dispatch({
      type: FETCH_CONVERSATIONS_SUCCESS,
      payload: conversations,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CONVERSATIONS_ERROR,
      payload: error,
    });
  }
};

export const updateLocalConversationTitle = (conversationId, newTitle) => ({
  type: 'UPDATE_LOCAL_CONVERSATION_TITLE',
  payload: { conversationId, newTitle },
});


export const setCurrentConversationAction = (conversationId) => ({
    type: 'SET_CURRENT_CONVERSATION',
    payload: conversationId
});

export const createTemporaryConversation = () => {
  return {
      type: 'CREATE_TEMPORARY_CONVERSATION',
      payload: {
          id: 'temp-id',
          title: 'New Conversation',
          timestamp: new Date().toISOString(),
          messageIds: []
      }
  };
};

export const updateConversationAction = (conversation) => async (dispatch) => {
  try {

    // Update in Firebase
    await firebaseRepository.updateConversation(conversation);
    // Dispatch action to update Redux state
    dispatch({
      type: 'UPDATE_CONVERSATION',
      payload: conversation,
    });
  } catch (error) {
    // Handle errors, potentially dispatching an error action
  }
};

export const createConversationAction = () => async (dispatch) => {
  try {
      const newConversation = await firebaseRepository.createNewConversation();
      dispatch({
          type: 'CREATE_CONVERSATION',
          payload: newConversation,
      });

      return newConversation; // Return the new conversation
  } catch (error) {
      console.error('Error creating new conversation:', error);
  }
};

export const deleteConversationAction = (conversationId) => async (dispatch) => {
  try {
      await firebaseRepository.deleteConversation(conversationId);

      dispatch({
          type: 'DELETE_CONVERSATION',
          payload: conversationId,
      });
  } catch (error) {
      console.error('Error deleting conversation:', error);
      // Optionally, dispatch an error action here
  }
};