import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import rehypeHighlight from 'rehype-highlight';
import 'highlight.js/styles/vs2015.css';
import './AssistantCell.css';
import logo from '../images/logo-assistant.png';



function AssistantCell({ message, isTyping }) {
    // Convert Markdown to HTML
    return (
        <div style={{ padding: '10px 15px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ marginRight: '10px' }}>
                    <div style={{
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                    }}>
                        <img src={logo} alt="Assistant Logo" style={{ width: '100%', height: '100%' }} />
                    </div>
                </div>
                <div style={{ flex: 1 }} className="markdown-container">
                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]}>
                        {message}
                    </ReactMarkdown>
                    {isTyping && <span className="typing-cursor">|</span>}
                </div>
            </div>
        </div>
    );
}

export default AssistantCell;
