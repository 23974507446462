// ExamplePromptCell.js
import React from 'react';

// Props include the prompt text and the onClick handler
const ExamplePromptCell = ({ promptText, onCellTap }) => {
  return (
    <div
      className="p-4 m-2 bg-gray-200 rounded-lg cursor-pointer hover:bg-gray-300"
      onClick={() => onCellTap(promptText)}
    >
      {promptText}
    </div>
  );
};

export default ExamplePromptCell;
