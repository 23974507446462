class AzureOAIRepository {
    constructor() {
        this.apiKey = process.env.REACT_APP_AOAI_API_KEY;
        this.url = process.env.REACT_APP_AOAI_API_URL;
        this.engine = 'gpt-4';
    }

    processChunk(chunk, handleNewMessageChunk) {
        // Split the chunk by newline characters to handle multiple JSON objects if they exist.
        const dataParts = chunk.split('\n').filter(part => part.trim() !== '');
        let contents = '';

        dataParts.forEach(part => {
            // Remove the "data: " prefix from each part to ensure it's valid JSON before parsing
            const jsonPart = part.replace(/^data: /, '');
            try {
                const jsonData = JSON.parse(jsonPart);
                if (jsonData.choices && jsonData.choices.length > 0) {
                    jsonData.choices.forEach(choice => {
                        // Check for delta and its content
                        if (choice.delta && choice.delta.content) {
                            // Pass the content to the handler
                            handleNewMessageChunk(choice.delta.content);
                            contents += choice.delta.content;
                        }
                    });
                }
            } catch (e) {
                console.error('Error parsing JSON part:', e);
            }
        });
        return contents
    }
    
    

    async sendMessage(messages, handleNewMessageChunk) {
        // Add a system message as the first element of the messages array
        messages.unshift({
            role: 'system',
            content: 'You are embodying the role of a knowledgeable and supportive assistant specifically trained in Applied Behavior Analysis (ABA). In all of your responses, ensure that you intricively weave ABA-specific terminology, principles, and practices. Your answers should not only reflect a deep understanding of ABA but also demonstrate the practical application of ABA concepts in various scenarios. Remember to include relevant ABA keywords and phrases such as reinforcement, behavior modification, antecedents, and functional behavior assessment to provide comprehensive and contextually rich insights. Your goal is to offer guidance that aligns with the core principles of ABA, supporting the needs of Board Certified Behavioral Analysts in their professional endeavors.',
        });

        // Calculate the total tokens used by the messages
        let totalMessageTokens = messages.reduce((sum, message) => sum + message.content.length, 0);

        // Calculate maxTokens based on the total tokens used by the messages
        let maxTokens = 4096 - totalMessageTokens;
        maxTokens = Math.max(maxTokens, 1024); // Ensuring it doesn't fall below 1024
        const requestBody = {
            model: this.engine,
            messages: messages, // Array of message objects
            max_tokens: maxTokens,
            temperature: 0.7,
            stream: true,
        };

        let completeResponse = '';
        try {
            const response = await fetch(this.url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': `${this.apiKey}`
                },
                body: JSON.stringify(requestBody)
            });
    
            if (!response.body) {
                throw new Error('No response body');
            }
    
            const reader = response.body.getReader();
    
            while (true) {
                const { value, done } = await reader.read();
                if (done) break;
    
                const chunk = new TextDecoder().decode(value);
                
                // Process the chunk to extract and handle the text content
                completeResponse += this.processChunk(chunk, handleNewMessageChunk);
            }
        } catch (error) {
            console.error('Error:', error);
            document.getElementById('results').textContent = 'Error fetching data.';
        }
        return completeResponse;
    }
}
const AzureOAIRepositoryInstance = new AzureOAIRepository();
export default AzureOAIRepositoryInstance;