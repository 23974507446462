// src/reducers/conversationReducer.js
import {
    CONVERSATIONS_LOADING,
    FETCH_CONVERSATIONS_SUCCESS,
    FETCH_CONVERSATIONS_ERROR,
} from '../actions/conversationActions';

const initialState = {
    conversations: {},
    currentConversationId: null,
    isLoading: false,
    error: null,
};


const conversationReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONVERSATIONS_LOADING:
            return { ...state, isLoading: true };
        case FETCH_CONVERSATIONS_SUCCESS:
            // Map the fetched conversations into an object keyed by their IDs
            const fetchedConversations = action.payload.reduce((acc, conversation) => {
                acc[conversation.id] = conversation;
                return acc;
            }, {});
            return { ...state, isLoading: false, conversations: fetchedConversations };
        case FETCH_CONVERSATIONS_ERROR:
            return { ...state, isLoading: false, error: action.payload };
        case 'UPDATE_CONVERSATION':
            // Log current state and action payload for debugging
            console.log('Current State:', state);
            console.log('Action Payload:', action.payload);

            // Update the conversation in the state
            const updatedState = {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.payload.id]: action.payload
                }
            };

            // Log the updated state
            console.log('Updated State:', updatedState);

            return updatedState;

        case 'CREATE_TEMPORARY_CONVERSATION':
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    'temp-id': action.payload
                },
                currentConversationId: 'temp-id'
            };

        case 'UPDATE_LOCAL_CONVERSATION_TITLE':
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.payload.conversationId]: {
                        ...state.conversations[action.payload.conversationId],
                        title: action.payload.newTitle
                    }
                }
            };
        case 'CREATE_CONVERSATION':
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.payload.id]: action.payload
                }
            };
        case 'DELETE_CONVERSATION':
            const { [action.payload]: deletedConversation, ...remainingConversations } = state.conversations;
            return {
                ...state,
                conversations: remainingConversations
            };
        case 'SET_CURRENT_CONVERSATION':
            return {
                ...state,
                currentConversationId: action.payload
            };
        default:
            return state;
    }
};

export default conversationReducer;
