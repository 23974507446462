import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

function DropdownMenu({ conversation, onRename, onDelete }) {
    const [isOpen, setIsOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef(null);


    const handleRenameClick = () => {
        // Trigger the editing mode for this conversation
        onRename(conversation.id);
        setIsOpen(false); // Close the dropdown menu
    };
    
    const handleDeleteConversation = () => {
        onDelete(conversation.id)
        setIsOpen(false);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (buttonRef.current && !buttonRef.current.contains(event.target) && !event.target.matches('.dropdown-button')) {
                console.log('options closed');
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (buttonRef.current && isOpen) {
            const rect = buttonRef.current.getBoundingClientRect();
            setMenuPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX
            });
        }
    }, [isOpen]);

    const dropdownMenu = isOpen && (
        <div
            style={{
                position: 'absolute',
                top: menuPosition.top,
                left: menuPosition.left,
                zIndex: 1000
            }}
            className="origin-top-right mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        >
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        handleRenameClick();
                        setIsOpen(false); // This will close the dropdown
                    }}
                    className="dropdown-button block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                    Rename
                </button>

                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteConversation();
                        setIsOpen(false); // This will close the dropdown
                    }}
                    className="dropdown-button block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                    Delete
                </button>

                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen(false); // This will close the dropdown
                    }}
                    className="dropdown-button block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                    Archive
                </button>
            </div>

        </div>
    );

    return (
        <div className="relative inline-block text-left">
            <div ref={buttonRef}>
                <button onClick={() => setIsOpen(!isOpen)} className="flex items-center text-gray-600 hover:text-gray-800 focus:outline-none">
                    <i className="fas fa-ellipsis-v"></i>
                </button>
            </div>
            {ReactDOM.createPortal(dropdownMenu, document.body)}
        </div>
    );
}

export default DropdownMenu;
