// src/models/Conversation.js

class Conversation {
    constructor(timestamp, title, messages, id, messageIds) {
      this.id = id;
      this.timestamp = timestamp;
      this.title = title;
      this.messages = messages; // Array of Message objects
      this.messageIds = messageIds;
      this.mostRecentSize = messageIds.length;
    }
  
    isEdited() {
      return this.mostRecentSize !== this.messageIds.length;
    }
  
    static fromFirestore(firestoreData) {
      // Convert Firestore Timestamp to JavaScript Date object
      const timestamp = new Date(firestoreData.timestamp.seconds * 1000);
      const title = firestoreData.title;
      const id = firestoreData.id;
      const messageIds = firestoreData.messageIds;
      const messages = []; // Convert Firestore message data to Message objects
      return new Conversation(timestamp, title, messages, id, messageIds);
    }
  
    update() {
      this.mostRecentSize = this.messageIds.length;
    }
  
    addMessage(message) {
      this.messages.push(message);
    }
  
    removeLastMessage() {
      if (this.messages.length > 0) {
        this.messages.pop();
      }
    }
  }
  
  export default Conversation;
  