import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getUserProfileAction } from './actions/userActions';
import Auth from './components/Auth';
import HomeView from './components/HomeView';
import LoadingComponent from './components/LoadingPage'; // Import your LoadingComponent

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state for loading status
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false); // Set loading to false after auth check
      if (user) {
        dispatch(getUserProfileAction(user.uid));
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  if (isLoading) {
    return <LoadingComponent />; // Show loading screen while checking authentication
  }

  return (
    <Router>
      <div className="">
        <Routes>
          <Route path="/" element={isAuthenticated ? <HomeView /> : <Navigate to="/auth" />} />
          <Route path="/auth" element={!isAuthenticated ? <Auth /> : <Navigate to="/" />} />
          {/* Add other routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
