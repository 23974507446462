// src/reducers/userProfileReducer.js

const initialState = {
    user: null,
    userProfile: null,
    loading: false,
    error: null,
    isAuthenticated: false,
    registrationSuccessful: null,
    resetPasswordSuccessful: null,
};

const userProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CLEAR_ERROR':
            return { ...state, error: null, registrationSuccessful: null };
        case 'SIGN_IN_SUCCESS':
            return { ...state, user: action.payload, error: null, isAuthenticated: true };
        case 'SIGN_IN_FAILURE':
            return { ...state, error: action.payload, isAuthenticated: false };
        case 'SIGN_IN_EMAIL_NOT_VERIFIED':
            return { ...state, error: action.payload, isAuthenticated: false };
        case 'SIGNOUT':
            return { ...state, isAuthenticated: false, user: null, userProfile: null };
        case 'REGISTRATION_SUCCESS':
            return { ...state, loading: false, error: null, registrationSuccessful: true };
        case 'REGISTRATION_FAILURE':
            return { ...state, loading: false, error: action.payload, registrationSuccessful: false };
        case 'USER_PROFILE_LOADING':
            return { ...state, loading: true, error: null };
        case 'USER_PROFILE_SUCCESS':
            return { ...state, loading: false, userProfile: action.payload };
        case 'USER_PROFILE_ERROR':
            return { ...state, loading: false, error: action.payload };
        case 'UPDATE_USER_PROFILE':
            return { ...state, userProfile: action.payload };
        case 'RESET_PASSWORD_SUCCESS':
            return {...state, loading: false, error: null, resetPasswordSuccessful: true };
        case 'RESET_PASSWORD_FAILURE':
            return {...state, loading: false, error: action.payload, resetPasswordSuccessful: false };
        default:
            return state;
    }
};

export default userProfileReducer;
