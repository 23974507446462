// src/services/firebaseService.js
import FirebaseRepository from '../repositories/FirebaseRepository';

export const fetchUserProfile = async (userId) => {
    return await FirebaseRepository.fetchUserProfile(userId);
};

export const getConversations = async (conversationIds) => {
    return await FirebaseRepository.getConversations(conversationIds);
};

export const updateConversation = async (conversation) => {
    return await FirebaseRepository.updateConversation(conversation);
};

export const updateUserProfile = async (user) => {
    return await FirebaseRepository.updateUserProfile(user);
};

export const getMessages = async (messageIds) =>  {
    return await FirebaseRepository.getMessages(messageIds);
}

export const createMessage = async (role, content) => {
    return await FirebaseRepository.createMessage(role, content);
}

export const signOut = async () => {
    return await FirebaseRepository.signOut();
}

export const signIn = async (email, password) => {
    return await FirebaseRepository.signIn(email, password);
}

export const registerUser = async (fullName, email, password) => {
    return await FirebaseRepository.registerUser(fullName, email, password);
}

export const resetPassword = async (email) => {
    return await FirebaseRepository.resetPassword(email);
}