export const handleResendVerification = async (email) => {
    try {
      const response = await fetch(process.env.REACT_APP_CALYPSO_RESEND_EMAIL_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include other necessary headers, like authorization tokens
        },
        body: JSON.stringify({
          email: email, // Ensure you have the user's email, possibly from state or props
        }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Handle success response
      console.log('Verification email resent successfully');
    } catch (error) {
      console.error('Failed to resend verification email:', error);
    }
  };
