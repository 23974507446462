// src/components/LoadingPage.js

import React from 'react';
import './LoadingPage.css'; // Import the CSS file for styling

const LoadingComponent = () => {
  return (
    <div className="loading-container">
      <div className="loading-spinner"></div>
      <div style={{ height: '20px' }}></div> {/* Spacer */}
      <p>Loading...</p>
    </div>
  );
};

export default LoadingComponent;
