// src/components/SessionExpiredComponent.js

import React from 'react';

const SessionExpiredPage = ({ onRelogin }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Session Timeout</h2>
      <p>
        Looks like your session took a timeout. Let's get you back in the conversation!
      </p>
      <button onClick={onRelogin} style={{ textDecoration: 'underline', color: '#385c6c' }}>
        Re-login
      </button>
    </div>
  );
};

export default SessionExpiredPage;
