// src/reducers/messageReducer.js
import {
    FETCH_MESSAGES,
    FETCH_MESSAGES_SUCCESS,
    FETCH_MESSAGES_ERROR,
    CREATE_MESSAGE,
    CREATE_MESSAGE_SUCCESS,
    CREATE_MESSAGE_ERROR,
    CLEAR_MESSAGES,
} from '../actions/messageActions';
import { Message } from '../models/Message';

const initialState = {
    messages: [],
    loading: false,
    error: null,
};

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_MESSAGE':
            return {
                ...state,
                messages: [...state.messages, action.payload]
            };
        case CLEAR_MESSAGES:
            return {
                ...state,
                messages: [] // Clear the messages
            };
        case FETCH_MESSAGES:
            return { ...state, loading: true, error: null };
        case FETCH_MESSAGES_SUCCESS:
            return { ...state, loading: false, messages: action.payload };
        case FETCH_MESSAGES_ERROR:
            return { ...state, loading: false, error: action.payload };
        case CREATE_MESSAGE:
            return { ...state, loading: true };
        case 'ADD_MESSAGE_CHUNK':
            const newMessages = [...state.messages];
            const newContent = action.payload;

            if (newMessages.length > 0) {
                const lastMessage = newMessages[newMessages.length - 1];

                if (lastMessage.role === 'assistant' && lastMessage instanceof Message) {
                    // If the last message is from the assistant, append the new content
                    lastMessage.content += newContent;
                } else {
                    // If the last message is from the user, start a new assistant message
                    const newMessage = new Message('assistant', newContent);
                    newMessages.push(newMessage);
                }
            } else {
                // If there are no messages, start a new assistant message
                const newMessage = new Message('assistant', newContent);
                newMessages.push(newMessage);
            }

            return {
                ...state,
                messages: newMessages
            };
        case CREATE_MESSAGE_SUCCESS:
            if (action.payload.role === 'user') {
                return { ...state, loading: false, messages: [...state.messages, action.payload] };
            }
            return { ...state, loading: false };
        case CREATE_MESSAGE_ERROR:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default messageReducer;
