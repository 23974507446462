// src/components/LogoutDropdownMenu.js
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import "./LogoutDropdownMenu.css";

function LogoutDropdownMenu({ user, onLogout }) {
    const [isOpen, setIsOpen] = useState(false);
    const [popupPosition, setPopupPosition] = useState({});
    const buttonRef = useRef(null);
    const popupRef = useRef(null);

    const updatePopupPosition = () => {
        if (buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            setPopupPosition({
                top: buttonRect.top - window.scrollY - 100, // Adjust this value as needed
                left: buttonRect.left + window.scrollX,
            });
        }
    };

    useEffect(() => {
        if (isOpen) {
            updatePopupPosition();
        }
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const popupWindow = isOpen && (
        <div ref={popupRef} className="absolute p-4 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
             style={{ top: `${popupPosition.top}px`, left: `${popupPosition.left}px` }}>
            {/* Content of the popup */}
            <div role="menu" aria-orientation="vertical">
                <p className="text-sm text-gray-700">Logged in as {user.fullName}</p>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onLogout();
                    }}
                    className="mt-2 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                    Log Out
                </button>
            </div>
        </div>
    );

    return (
        <div className="relative inline-block text-left">
            <div ref={buttonRef}>
                <button onClick={() => {
                    setIsOpen(!isOpen);
                    updatePopupPosition();
                }} className="flex items-center text-gray-600 hover:text-gray-800 focus:outline-none">
                    <span>{user.fullName}</span>
                    <i className="fas fa-chevron-up ml-1"></i>
                </button>
            </div>
            {ReactDOM.createPortal(popupWindow, document.body)}
        </div>
    );
}

export default LogoutDropdownMenu;
