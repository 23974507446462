// src/store/index.js

import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import chatReducer from '../reducers/chatReducer.js'; // Import your reducer
import userProfileReducer from '../reducers/userProfileReducer';
import conversationReducer from '../reducers/conversationReducer';
import messageReducer from '../reducers/messageReducer.js';
import { composeWithDevTools } from 'remote-redux-devtools'; // Import composeWithDevTools

const rootReducer = combineReducers({
  chat: chatReducer,
  userProfile: userProfileReducer,
  conversation: conversationReducer,
  messageReducer
});

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk) // Apply thunk middleware within composeWithDevTools
  )
);

export default store;
