// src/components/Auth.js

import React from 'react';
import IdentityBox from './IdentityBox'; // Update the import path as necessary
import '../index.css';

function Auth() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    console.log('isMobile:', isMobile);
    return (
        <div className="min-h-screen flex">
            {isMobile ? (
                <div className="w-full flex justify-center items-center">
                    <IdentityBox />
                </div>
            ) : (
                <>
                    <div className="w-1/2 custom-bg text-white flex flex-col justify-center items-center text-center p-10">
                        <h1 className="text-4xl mb-2">Smart BCBA</h1>
                        <p className="text-2xl">Unlock the potential of AI in ABA!</p>
                    </div>
                    <div className="w-1/2 flex justify-center items-center">
                        <IdentityBox />
                    </div>
                </>
            )}
        </div>
    );
}

export default Auth;
