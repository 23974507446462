// Example of using the service in a Redux action
// src/actions/userActions.js

import { fetchUserProfile, updateUserProfile, signOut, signIn, registerUser, resetPassword } from '../services/FirebaseService';

export const getUserProfileAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: 'USER_PROFILE_LOADING' });
    const userProfile = await fetchUserProfile(userId);
    dispatch({ type: 'USER_PROFILE_SUCCESS', payload: userProfile });
  } catch (error) {
    dispatch({ type: 'USER_PROFILE_ERROR', payload: error });
  }
};

export const updateUserProfileAction = (user) => async (dispatch) => {
  try {
    await updateUserProfile(user);

    dispatch({
      type: 'UPDATE_USER_PROFILE',
      payload: user,
    });
  } catch (error) {
    console.error('Error updating user profile:', error);
    // Optionally, dispatch an error action here
  }
};

export const signOutAction = () => async (dispatch) => {
  try {
    await signOut();
    dispatch({ type: 'SIGNOUT' });

  } catch (error) {
    console.error('Error signing out user:', error);
  }
};

export const signInAction = (email, password) => async (dispatch) => {
  try {
    const userCredential = await signIn(email, password);
    const user = userCredential.user;

    if (!userCredential.success) {
      dispatch({ type: 'SIGN_IN_FAILURE', payload: userCredential.reason });
      return
    }

    // Dispatch an action for successful sign-in if the email is verified
    dispatch({ type: 'SIGN_IN_SUCCESS', payload: user });
  } catch (error) {
    // Dispatch an action for failed sign-in
    dispatch({ type: 'SIGN_IN_FAILURE', payload: error.message });
  }
};

export const registerUserAction = (email, password, fullName) => async (dispatch) => {
  try {
    const result = await registerUser(email, password, fullName);
    if (result.success) {
      // Dispatch success action
      dispatch({ type: 'REGISTRATION_SUCCESS' });
    } else {
      // Dispatch failure action with reason
      dispatch({ type: 'REGISTRATION_FAILURE', payload: result.reason });
    }
  } catch (error) {
    // Handle any other errors
    dispatch({ type: 'REGISTRATION_FAILURE', payload: error.message });
  }
};

export const resetPasswordAction = (email) => async (dispatch) => {
  try {
    const result =  await resetPassword(email);
    if (result.success) {
      dispatch({ type: 'RESET_PASSWORD_SUCCESS' });
    } else {
      dispatch({ type: 'RESET_PASSWORD_FAILURE', payload: result.reason})
    }
  } catch (error) {
    dispatch({ type: 'RESET_PASSWORD_FAILURE', payload: error.message})
  }
};
