import ChatRepository from '../repositories/ChatRepository'; // Update the path as necessary
import AzureOAIRepository from '../repositories/AzureOAIRepository'; // Update the path as necessary

export const ActionTypes = {
    SET_LOADING: 'SET_LOADING',
    SET_SESSION_EXPIRED: 'SET_SESSION_EXPIRED',
    SET_SESSION_ACTIVE: 'SET_SESSION_ACTIVE',
    SEND_MESSAGE: 'SEND_MESSAGE',
    SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
    SEND_MESSAGE_ERROR: 'SEND_MESSAGE_ERROR',
    // ... other action types as needed
};

export const setLoading = (isLoading) => ({
    type: ActionTypes.SET_LOADING,
    payload: isLoading,
});

export const setSessionExpired = () => ({
    type: ActionTypes.SET_SESSION_EXPIRED,
});

export const setSessionActive = () => ({
    type: ActionTypes.SET_SESSION_ACTIVE,
});

export const sendMessage = (formattedMessages, handleNewMessageChunk) => async (dispatch) => {
    dispatch({ type: ActionTypes.SEND_MESSAGE });

    try {
        let responseMessage;
        if (process.env.NODE_ENV === 'development') {
            responseMessage = await ChatRepository.sendMessage(formattedMessages, handleNewMessageChunk);

        } else {
            responseMessage = await AzureOAIRepository.sendMessage(formattedMessages, handleNewMessageChunk);
        }
        dispatch({
            type: ActionTypes.SEND_MESSAGE_SUCCESS,
            payload: responseMessage
        });
        return responseMessage;
    } catch (error) {
        dispatch({
            type: ActionTypes.SEND_MESSAGE_ERROR,
            payload: error
        });
    }
};

// Add other action creators as needed
 export const createConversationTitle = (message) => async (dispatch) =>{
    // Add your logic here to generate a conversation title based on the message
    try {
        const title = await ChatRepository.generateConversationTitle(message);
        return title;
    } catch (error) {
        console.error('Error creating conversation title:', error);
    }

};