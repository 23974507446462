import React, { useState, useEffect } from 'react';
import DropdownMenu from './DropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateConversationAction,
    fetchConversations,
    updateLocalConversationTitle,
    deleteConversationAction,
    setCurrentConversationAction // Import this action
} from '../actions/conversationActions';
import { updateUserProfileAction, signOutAction } from '../actions/userActions';
import { clearMessages } from '../actions/messageActions';
import './Sidebar.css';
import LogoutDropdownMenu from './LogoutDropdownMenu';


function Sidebar({ user, selectConversation, isSidebarOpen, toggleSidebar }) {
    const currentConversationId = useSelector(state => state.conversation.currentConversationId);
    const dispatch = useDispatch();
    const conversations = useSelector(state => state.conversation.conversations);
    const [editingConversationId, setEditingConversationId] = useState(null);

    // Convert the conversations object to an array
    const conversationArray = Object.values(conversations);

    const handleConversationClick = (conversation) => {
        dispatch(setCurrentConversationAction(conversation.id));
    };

    useEffect(() => {
        if (user && user.conversationIds) {
            dispatch(fetchConversations(user.conversationIds));
        }
    }, [user, dispatch]);

    const finishEditing = (conversationId) => {
        const conversationToUpdate = conversations[conversationId];
        if (conversationToUpdate) {
            dispatch(updateConversationAction(conversationToUpdate));
        }
        setEditingConversationId(null);
    };


    const createConversation = async () => {
        dispatch(clearMessages());
        const newConversation = {
            id: 'temp-id', // Hardcoded temporary ID
            title: 'New Conversation',
            timestamp: new Date().toISOString(),
            messageIds: []
        };

        selectConversation(newConversation.id);
    };

    const handleDeleteConversation = async (conversationId) => {
        await dispatch(deleteConversationAction(conversationId));

        // Assuming 'userProfile' is the current user's profile state
        const updatedUserProfile = {
            ...user,
            conversationIds: user.conversationIds.filter(id => id !== conversationId),
        };

        dispatch(updateUserProfileAction(updatedUserProfile));
        createConversation();
    };

    const handleSignOut = async () => {
        await dispatch(signOutAction());
    }

    const startEditing = (conversationId) => {
        setEditingConversationId(conversationId);
    };

    const handleKeyDown = (e, conversationId) => {
        if (e.key === 'Enter') {
            finishEditing(conversationId);
        }
    };

    const handleTitleChange = (e, conversationId) => {
        dispatch(updateLocalConversationTitle(conversationId, e.target.value));
    };


    return (
        <div className={`${!isSidebarOpen ? 'sidebar-closed' : 'sidebar-container'}`}>
            <div className="flex items-center justify-between h-16 px-4 border-b">
                <div>
                    <button onClick={createConversation} className="text-gray-600 focus:outline-none mx-1">
                        <i className="fas fa-comment-alt"></i>
                    </button>
                </div>
            </div>
            <div className="overflow-y-auto">
                {conversationArray.reverse().map((conversation, index) => (
                    <div key={index} onClick={() => handleConversationClick(conversation)} className="flex justify-between items-center px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        {editingConversationId === conversation.id ? (
                            <input
                                type="text"
                                value={conversation.title}
                                onChange={(e) => handleTitleChange(e, conversation.id)}
                                onBlur={() => finishEditing(conversation.id)}
                                onKeyDown={(e) => handleKeyDown(e, conversation.id)}
                                autoFocus
                            />
                        ) : (
                            <p className="text-sm font-semibold">{conversation.title}</p>
                        )}
                        {conversation.id === currentConversationId && (
                            <DropdownMenu
                                dropdownId={`dropdown-${index}`}
                                conversation={conversation}
                                onRename={startEditing}
                                onDelete={handleDeleteConversation}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className="mt-auto"> {/* This pushes the logout menu to the bottom */}
                <LogoutDropdownMenu user={user} onLogout={() => { handleSignOut() }} />
            </div>
        </div>
    );
}

export default Sidebar;
