export class Message {
  constructor(role, content, id, timestamp, isNew = true) {
      this.role = role;
      this.content = content;
      this.id = id;
      this._timestamp = timestamp;
      this.isNew = isNew;
  }

  toJsonForGPT() {
      return {
          role: this.role,
          content: this.content,
      };
  }

  toJsonForFirestore() {
      return {
          role: this.role,
          content: this.content,
          timestamp: this._timestamp,
          id: this.id,
      };
  }

  static fromFirestore(firestoreData) {
      return new Message(
          firestoreData['role'],
          firestoreData['content'],
          firestoreData['id'],
          firestoreData['timestamp'].toDate(),
          false
      );
  }

  get timeStamp() {
      return this._timestamp;
  }
}
